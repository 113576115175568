import { CirclePlay } from 'lucide-react';
import React from 'react';
import CTAButton from './CTAButton';

interface ContainerProps {
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

interface ClientLogoProps {
  src: string;
  alt: string;
  className?: string;
}

const ClientLogo: React.FC<ClientLogoProps> = ({ src, alt, className = "h-12 w-auto mx-auto" }) => (
  <div className="p-4 grayscale transition duration-200 hover:grayscale-0">
    <img src={src} className={className} loading="lazy" alt={alt} />
  </div>
);

const Hero: React.FC = () => {
  return (
    <div className="relative flex flex-col min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('./home_bg.png')", backgroundRepeat: 'no-repeat' }} id="home">
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      
      <Container>
        <div className="relative pt-36 ml-auto">
          <div className="mt-24 mx-auto">
            <h1 className="text-white font-bold text-2xl md:text-3xl xl:text-6xl lg:w-2/4">
              EXPLORE YOUR WILDEST DREAMS
            </h1>
            <div className='w-16 bg-limegreen h-2 mt-4'></div>
            <p className="mt-4 text-white lg:w-2/3 xl:w-1/2">
              Regardless of class or occasion, our e-bikes at Bug-X takes you to places unchartered, letting you explore every bit of your imagination, and turning them into fulfillment.
            </p>
            <CTAButton className='mt-8' title='PRE-ORDER' />
            
            <div className="flex items-center mt-12">
              <CirclePlay className="text-white w-12 h-12 cursor-pointer hover:text-limegreen transition-colors duration-300" />
              <span className="ml-4 text-white">Watch our story</span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;