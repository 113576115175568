import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const WhatsBugX: React.FC = () => {
  return (
    <div id="whatsbugx" className='py-32 bg-darkgrey'>
      <Container>      
        <div className="space-y-12 justify-between text-gray-600 md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center w-2/3 mx-auto">
          <div className="md:7/12 lg:w-1/2">
            <h2 className="text-5xl font-bold text-white md:text-4xl dark:text-white uppercase">
              What's in a bug-x e-bike.
            </h2>
            <div className='w-16 bg-limegreen h-2 mt-4 rounded'></div>
            <p className="my-4 text-white">
            Engineered to empower limitless exploration, our innovative Bug-X e-bikes enable riders to ascend further and go the extra mile with exceptional comfort and control. At Bug-X, we cater to diverse preferences and budgets through cutting-edge technologies and premium materials that redefine the cycling experience.
            </p>
          </div>
          <div className="md:5/12 lg:w-1/3">
            <img
              src="./bugx_explore_white.svg"
              alt="image"
              loading="lazy"
              className="w-full"
            />
          </div>          
        </div>
      </Container>
    </div>
  );
};

export default WhatsBugX;