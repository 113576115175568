import { StarHalfIcon, StarIcon, StarOffIcon, Stars } from 'lucide-react';
import CTAButton from './CTAButton';
import CTAButtonAlt from './CTAButtonAlt';

interface ContainerProps {
   children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
   <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const testimonials = [
   {
      key: 'testimonial-1',
      name: 'Cathy Monroe',
      rating: 4,
      review:
         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris{',
   },
   {
      key: 'testimonial-2',
      name: 'Cathy Monroe',
      rating: 4,
      review:
         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris{',
   },
];

const Testimonial: React.FC = () => {
   return (
      <div id="transmission" className="py-24 bg-white">
         <Container>
            <div className="space-y-12 justify-between md:flex md:gap-6 md:space-y-0 lg:gap-2 lg:items-center mx-auto">
               <div className="md:7/12 lg:w-1/3">
                  <h2 className="text-1xl font-bold uppercase tracking-wider text-black">
                     OUR CLIENT REVIEWS
                  </h2>
                  <h3 className="text-4xl font-bold text-black uppercase tracking-widest">
                     THEY SAID IT. “NOT US!”
                  </h3>
                  <div className="w-16 bg-limegreen h-1 my-5 rounded"></div>
               </div>

               {testimonials.map((item) => (
                  <div className="flex gap-4 lg:w-1/3">
                     <img
                        src="./photo.png"
                        alt="reviewer"
                        className="w-fit h-fit"
                     />						
                     <div>
                        <div className="text-1xl font-bold tracking-wider text-black">
                           {item.name}
                        </div>
                        <div className="flex">
                           <StarIcon />
                           <StarIcon />
                           <StarIcon />
                           <StarIcon />
                           <StarHalfIcon />
                        </div>
                        <p>
                           {item.review}
                        </p>
                     </div>
                  </div>
               ))}
            </div>
         </Container>
      </div>
   );
};

export default Testimonial;
