import CTAButton from "./CTAButton";
import CTAButtonAlt from "./CTAButtonAlt";

interface ContainerProps {
	children: React.ReactNode;
  }
  
  export const Container: React.FC<ContainerProps> = ({ children }) => (
	<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
  );
  
  const ComingSoon: React.FC = () => {
	return (
	  <div id="transmission" className='pt-8 bg-darkgreen'>
		<Container>      
		  <div className="space-y-12 justify-between md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-2 lg:items-center w-2/3 mx-auto">   
			<div className="md:7/12 lg:w-1/2">
			  <h2 className="text-1xl font-bold uppercase tracking-wider text-white">
			  BUG-X MOBILE APP
			  </h2>
			  <h3 className="text-4xl font-bold text-white uppercase tracking-widest">
			  COMING SOON
			  </h3>
			  <div className='w-16 bg-white h-1 my-5 rounded'></div>
			  <p className="my-4 text-white">
			  Our teams are working hard to make your riding experience more fun. You’ll soon be able to install our free app on your smart devices and stay ahead of the game.
			  </p>
			  <CTAButton title='DOWNLOAD' />
			</div>      
			<div className="md:5/12 lg:w-1/3">
			  <img
				src="./coming_soon.png"
				alt="image"
				loading="lazy"
				className="w-full"
			  />
			</div>                     
		  </div>
		</Container>
	  </div>
	);
  };
  
  export default ComingSoon;