import React from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import BugXWebsiteHome from "../components/BugXWebsiteHome";
import Home from "../views/home/Home";

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                {/* <Route
                        path={Path.ROOT}
                        element={
                            <PublicRoute>
                                <BugXWebsiteHome />
                            </PublicRoute>
                        }
                    /> */}

                <Route
                    path={Path.ROOT}
                    element={
                        <PublicRoute>
                            <Home />
                        </PublicRoute>
                    }
                />












                {/* <Route
                        path={Path.GEOFENCE_DETAILS}
                        element={
                            <PrivateRoute>
                                <MapView/>
                            </PrivateRoute>
                        }
                    /> */}
            </Routes>
        </Router>
    );
};

export const Path = {
    ROOT: "/",
    LOGIN: "/login",
};

export const Links = {
    geofenceDetail: (geofenceId: number) => `/map/geofence/${geofenceId}`,
    ROOT: "/",
    LOGIN: "/login",
};

export default AppRoutes;
