import CTAButton from "./CTAButton";
import CTAButtonAlt from "./CTAButtonAlt";

interface ContainerProps {
   children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
   <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const TalkToUs: React.FC = () => {
   return (
      <div id="transmission" className="py-24 bg-white">
         <Container>
            <div className="space-y-12 justify-between md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center mx-auto">
               <div className="md:7/12 lg:w-2/5">
                  <div className="flex flex-col gap-2">
                     <label htmlFor="name">Name</label>
                     <input
					 	id="name"
						name="name"
                        className="w-full h-10 bg-whitesmoke px-3"
                        placeholder="Name"
                     />
                  </div>
                  <div className="flex flex-col gap-2 mt-3">
                     <label htmlFor="email">Email</label>
                     <input
					 	id='email'
						name="email"
                        className="w-full h-10 bg-whitesmoke px-3"
                        placeholder="Email"
                     />
                  </div>
                  <div className="flex flex-col gap-2 mt-3">
                     <label htmlFor="phone">Phone</label>
                     <input
					 	id='phone'
						name="phone"
                        className="w-full h-10 bg-whitesmoke px-3"
                        placeholder="Phone"
                     />
                  </div>
                  <div className="flex flex-col gap-2 mt-3">
                     <label htmlFor="message">Let us know how we can assist.</label>
                     <textarea
					 	id='message'
						name="message"
                        className="w-full h-40 bg-whitesmoke p-3"
                        placeholder="Your message"
                     />
                  </div>
				  <div className="flex flex-col gap-2 mt-3">
					<CTAButtonAlt title='SEND' />
				  </div>
               </div>
               <div className="md:7/12 lg:w-2/5">
                  <h2 className="text-1xl font-bold uppercase tracking-widest text-black">
                     TALK TO US
                  </h2>
                  <h3 className="text-4xl font-bold text-black uppercase">
                     SEND AN ENQUIRY TO OUR TEAM
                  </h3>
                  <div className="w-16 bg-limegreen h-1 my-7 rounded"></div>
                  <p className="my-4 text-black">
                     Whether you’d like to reserve your custom Bug-X bike or
                     need help fixing it, our teams are ready to assist. Simply
                     fill out the form and we’ll reach out to you as soon as
                     possible. <br />
                     <br />
                     Can’t wait? <a className="link text-sky-700 font-semibold" href="tel:+234 706 305 8322">Call</a> us directly.
                  </p>
               </div>			   
            </div>
         </Container>
      </div>
   );
};

export default TalkToUs;
