import React, { useState } from 'react';
import BugXLogoIcon from '../icons/BugXLogoIcon';
import { ChevronDown, Search } from 'lucide-react';

interface ContainerProps {
   children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => (
   <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const Navigation: React.FC = () => {
   const [isNavOpen, setIsNavOpen] = useState(false);

   const toggleNav = () => {
      setIsNavOpen(!isNavOpen);
   };

   const pages = [
      {
         name: 'E-Bikes',
         classNames: 'flex items-center',
         subsections: [{ name: 'Subsection 1', href: '#' }],
         href: '#',
      },
      {
         name: 'Charging',
         subsections: [{ name: 'Subsection 1', href: '#' }],
         href: '#',
      },
      {
         name: 'Support',
         href: '#',
      },
      {
         name: 'Support',
         href: '#',
      },
   ];

   return (
      <header>
         <nav className="z-10 w-full fixed bg-darkgrey text-white">
            <Container>
               <div className="flex flex-wrap items-center justify-between py-2 gap-6 md:py-4 md:gap-0 relative">
                  <div className="relative z-20 w-full flex justify-between lg:w-max md:px-0">
                     <a
                        href="#home"
                        aria-label="logo"
                        className="flex space-x-2 items-center"
                     >
                        <BugXLogoIcon />
                     </a>

                     <div className="relative flex items-center lg:hidden max-h-10">
                        <button
                           onClick={toggleNav}
                           aria-label="hamburger"
                           id="hamburger"
                           className="relative p-6 -mr-6"
                        >
                           <div
                              aria-hidden="true"
                              id="line"
                              className={`m-auto h-0.5 w-5 rounded bg-white dark:bg-gray-300 transition duration-300 ${
                                 isNavOpen ? 'rotate-45 translate-y-1.5' : ''
                              }`}
                           ></div>
                           <div
                              aria-hidden="true"
                              id="line2"
                              className={`m-auto mt-2 h-0.5 w-5 rounded bg-white dark:bg-gray-300 transition duration-300 ${
                                 isNavOpen ? '-rotate-45 -translate-y-1' : ''
                              }`}
                           ></div>
                        </button>
                     </div>
                  </div>
                  <div
                     aria-hidden="true"
                     className={`fixed z-100 inset-0 h-screen w-screen bg-darkgrey backdrop-blur-2xl origin-top scale-y-0 transition duration-500 lg:hidden ${
                        isNavOpen ? 'scale-y-100' : ''
                     }`}
                  >
                     {/* Mobile Navigation Menu */}
                     <div
                        className={`lg:hidden fixed inset-0 z-30 bg-darkgrey w-full h-full transition-transform duration-300 ease-in-out ${
                           isNavOpen ? 'translate-x-0' : 'translate-x-full'
                        }`}
                     >
                        <div className="flex flex-col items-center justify-center h-full">
                           {pages.map((page) => (
                              <a
                                 key={page.name}
                                 href={page.href}
                                 className="text-limegreen text-2xl my-4 hover:underline"
                                 onClick={toggleNav}
                              >
                                 {page.name}
                              </a>
                           ))}
                           <button className="text-limegreen text-2xl my-4">
                              <Search size={24} />
                           </button>
                        </div>
                     </div>

                     {/* Desktop Navigation Menu */}
                  </div>
                  <div
                     className={`flex-col z-20 flex-wrap gap-6 p-8 rounded-3xl border border-gray-100 bg-white shadow-2xl shadow-gray-600/10 justify-center w-full invisible opacity-0 translate-y-1 absolute top-full left-0 transition-all duration-300 scale-95 origin-top 
                          lg:relative lg:scale-100 lg:peer-checked:translate-y-0 lg:translate-y-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:p-0 lg:bg-transparent lg:w-7/12 lg:visible lg:opacity-100 lg:border-none
                          dark:shadow-none dark:bg-gray-800 dark:border-gray-700
                          ${isNavOpen ? 'scale-100 opacity-100 visible' : ''}`}
                  >
                     <div className="text-gray-600 dark:text-gray-300 lg:pr-0 lg:w-auto w-full lg:pt-0">
                        <ul className="tracking-wide font-medium lg:text-sm flex-col flex lg:flex-row lg:gap-4 items-center gap-8">
                           {pages.map((page) => (
                              <li
                                 key={page.name}
                              >
                                 <div className='flex flex-col items-center'>
                                 <a
                                    href={`#${page.name.toLowerCase()}`}
                                    className="block md:px-4 transition hover:text-primary text-limegreen"
                                 >
                                    <span className='hover:border-b-0 border-limegreen hover:font-bold'>{page.name}</span>
                                    {/* {page.subsections && (
                                       <ChevronDown className="inline-block" />
                                    )} */}
                                 </a>
                                 {/* <div id='nav-border-1' className='bg-limegreen hover:block h-1 w-9 mr-4' /> */}
                                 </div>
                              </li>
                           ))}
                        </ul>
                     </div>
                     <button className="mt-12 lg:mt-0 hidden lg:block">
                        <a
                           href="#"
                           className="relative flex h-9 w-9 items-center justify-center transition duration-300 ease-in-out"
                        >
                           <Search size={16} className='hover:font-semibold' />
                        </a>
                     </button>
                  </div>

                     <a className="bg-limegreen text-darkgrey px-4 py-2 rounded-xl font-bold hidden lg:block" href='#'>
                        Pre-order
                     </a>
               </div>
            </Container>
         </nav>
      </header>
   );
};

export default Navigation;
