
interface ContainerProps {
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const Safety: React.FC = () => {
  return (
    <div id="transmission" className='py-24 bg-white'>
      <Container>      
        <div className="space-y-12 justify-between md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center mx-auto">   
          <div className="md:7/12 lg:w-2/5">
            <h2 className="text-1xl font-bold uppercase tracking-widest text-black">
            SAFETY
            </h2>
            <h3 className="text-6xl font-bold text-black uppercase">
            YOU’VE GOT SPEED, GET ATTENTION TOO.
            </h3>
            <div className='w-16 bg-limegreen h-1 my-7 rounded'></div>
            <p className="my-4 text-black">
            Your e-bike’s integrated sound alert system produces a clear, attention-grabbing tone that’s effective without being disruptive. Engineered to cut through ambient noise, it ensures you’re heard when it matters most, enhancing rider safety in busy environments, especially given your new found speed.
            </p>
          </div>      
          <div className="md:5/12 lg:w-2/5">
            <img
              src="./youve_got_speed.png"
              alt="image"
              loading="lazy"
              className="w-full"
            />
          </div>                     
        </div>
      </Container>
    </div>
  );
};

export default Safety;