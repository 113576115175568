import React, { useEffect, useRef, useState } from 'react';
import Transmission from './sections/Transmission';
import Tracker from './sections/Tracker';
import Lights from './sections/Lights';
import Safety from './sections/Safety';
import Range from './sections/Range';
import Charging from './sections/Charging';
import Display from './sections/Display';
import { ArrowLeft, ArrowRight } from 'lucide-react';

const sections = [
   {
      key: 'arrow-left',
      title: <ArrowLeft />,
   },
   {
      key: 'transmission',
      title: 'Transmission',
      component: <Transmission />,
      href: '#transmission',
   },
   {
      key: 'tracker',
      title: 'Tracker',
      component: <Tracker />,
      href: '#tracker',
   },
   {
      key: 'lights',
      title: 'Lights',
      component: <Lights />,
      href: '#lights',
   },
   {
      key: 'safety',
      title: 'Safety',
      component: <Safety />,
      href: '#safety',
   },
   {
      key: 'range',
      title: 'Range',
      component: <Range />,
      href: '#range',
   },
   {
      key: 'display',
      title: 'Display',
      component: <Display />,
      href: '#display',
   },
   {
      key: 'charging',
      title: 'Charging',
      component: <Charging />,
      href: '#charging',
   },
   {
      key: 'arrow-right',
      title: <ArrowRight />,
   },
];

const HomeSectionsContainer: React.FC = () => {
   const ref = useRef<HTMLDivElement>(null);
   const [isSticky, setIsSticky] = useState(false);

   useEffect(() => {
      const handleScroll = () => {
         if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            // Only update state if it changes to avoid unnecessary re-renders
            if (rect.top <= 68 && !isSticky) {
               setIsSticky(true);
            } else if (rect.top > 68 && isSticky) {
               setIsSticky(false);
            }
         }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, [isSticky]);

   return (
      <div>
         <div
            id="home-sections-container"
            ref={ref}
            className={`bg-darkgrey flex flex-col border-t-2 ${
               isSticky ? 'sticky top-16 z-10' : ''
            }`}
         >
            <ul className="gap-8 flex flex-row justify-center w-full">
               {sections.map((section) => (
                  <li
                     key={section.key}
                     className="text-white uppercase hover:bg-limegreen p-3 cursor-pointer hover:transition-all hover:duration-300 hover:font-semibold hover:text-black tracking-widest"
                  >
                     {section.title}
                  </li>
               ))}
            </ul>
         </div>
		 <div>{sections.map((section) => section.component)}</div>
      </div>
   );
};

export default HomeSectionsContainer;
