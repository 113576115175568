import { ArrowRight, CirclePlay } from 'lucide-react';
import React from 'react';
import CTAButton from './CTAButton';

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className }) => (
  <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${className}`}>{children}</div>
);

const ElectrifyOldBike: React.FC = () => {
  return (
    <div className="relative flex flex-col bg-cover bg-center" style={{ backgroundImage: "url('./old_bike.png')", backgroundRepeat: 'no-repeat' }} id="home">
      <div className="relative inset-0 bg-black bg-opacity-10"></div>
      <Container className='flex'>
	  	<div className='w-11/12' />
        <div className="relative flex flex-col items-end py-16 w-2/3">
			<div className='flex flex-col flex-wrap'>
            <h1 className="text-white font-bold text-2xl md:text-3xl xl:text-4xl">
				WANT TO ELECTRIFY YOUR OLD BIKE?
            </h1>
            <div className='w-16 bg-limegreen h-2 mt-4'></div>
            <p className="mt-4 text-white">
				Got an old bike, on a budget or just not ready for a brand new Bug-X bike? Send us your regular bicycle and we’ll electrify it. You only need to fill out a couple details and you’re done.
            </p>
            <CTAButton className='mt-8' title={<div className='flex gap-2'>GET STARTED <ArrowRight className='hover:translate-x-2 duration-500' fontSize={8} /></div>} />
			</div>
        </div>
      </Container>
    </div>
  );
};

export default ElectrifyOldBike;