import React from "react";

function BugXLogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="131"
      height="28.328"
      viewBox="0 0 131 28.328"
    >
      <defs>
        <filter id="Path_9966">
          <feOffset dy="-43"></feOffset>
          <feGaussianBlur result="blur" stdDeviation="20"></feGaussianBlur>
          <feFlood floodColor="#3b692c" result="color"></feFlood>
          <feComposite
            in="SourceGraphic"
            in2="blur"
            operator="out"
          ></feComposite>
          <feComposite in="color" operator="in"></feComposite>
          <feComposite in2="SourceGraphic" operator="in"></feComposite>
        </filter>
        <filter id="Path_9972">
          <feOffset dy="-43"></feOffset>
          <feGaussianBlur result="blur-2" stdDeviation="20"></feGaussianBlur>
          <feFlood floodColor="#3b692c" result="color-2"></feFlood>
          <feComposite
            in="SourceGraphic"
            in2="blur-2"
            operator="out"
          ></feComposite>
          <feComposite in="color-2" operator="in"></feComposite>
          <feComposite in2="SourceGraphic" operator="in"></feComposite>
        </filter>
      </defs>
      <g data-name="Group 31277" transform="translate(-58 -27.351)">
        <text
          fill="#fff"
          fontFamily="Montserrat-Bold, Montserrat"
          fontSize="20"
          fontWeight="700"
          letterSpacing=".24em"
          transform="translate(101 48)"
        >
          <tspan x="0" y="0">
            BUG-X
          </tspan>
        </text>
        <g data-name="Group 31506" transform="translate(59 27.351)">
          <g data-name="Group 26152" transform="translate(-1)">
            <g data-name="Group 31554" transform="translate(25.038 17.176)">
              <g data-name="Group 31553">
                <g data-name="Group 31552">
                  <path
                    fill="#fff"
                    d="M863.933 483.421c-.755 3.8-1.031 8.344-.184 9.034l-.8 1c-.461-.368-1.8-2.137-.405-9.633l-1.7-.285a7.205 7.205 0 00.414-1.234l2.809.451z"
                    data-name="Path 9961"
                    transform="translate(-860.839 -482.307)"
                  ></path>
                </g>
              </g>
            </g>
            <g data-name="Group 31556" transform="translate(24.706 10.094)">
              <g data-name="Group 31555">
                <path
                  fill="#fff"
                  d="M867.082 484.075l-.507 1.188c-.534-.221-2.312-1.566-3.021-9.2l-2.542.276a8.148 8.148 0 00-.313-.783 4.076 4.076 0 00-.221-.451l4.255-.488.055.672c.312 3.887 1.281 8.353 2.294 8.786z"
                  data-name="Path 9962"
                  transform="translate(-860.479 -474.617)"
                ></path>
              </g>
            </g>
            <g data-name="Group 31559" transform="translate(16.854)">
              <g data-name="Group 31557">
                <path
                  fill="#fff"
                  d="M860.84 478.622a6.658 6.658 0 01-.062.839 7.674 7.674 0 01-.258 1.372 7.2 7.2 0 01-.414 1.234 9.167 9.167 0 01-2.312 3.279 9.277 9.277 0 01-1.326 1 9.85 9.85 0 01-1.492.755 9.347 9.347 0 01-3.4.635h-.018v-24.079h.018a4.2 4.2 0 012.855 1.124 1.084 1.084 0 00.111 1.538 1.058 1.058 0 001.022.221 4.282 4.282 0 01.212 1.317 4.142 4.142 0 01-.156 1.124 3.66 3.66 0 01-.433 1.013 9.007 9.007 0 012.21 1.335c.12.1.249.2.359.313a8.215 8.215 0 01.967.995c.147.184.286.368.424.562a9.157 9.157 0 01.626 1.041 4.068 4.068 0 01.221.451 8.164 8.164 0 01.313.783 8.828 8.828 0 01.479 2.238 8.722 8.722 0 01.054.91z"
                  data-name="Path 9963"
                  transform="translate(-851.559 -463.657)"
                ></path>
              </g>
            </g>
            <g data-name="Group 31560" transform="translate(6.962)">
              <path
                fill="#fff"
                d="M849.736 463.657a4.221 4.221 0 00-2.855 1.124 1.086 1.086 0 01-1.133 1.759 4.329 4.329 0 00-.212 1.317 4.146 4.146 0 00.589 2.137 9.086 9.086 0 00-2.1 1.243 8.391 8.391 0 00-1.234 1.188 6.888 6.888 0 00-.617.774 8.213 8.213 0 00-.847 1.492.455.455 0 00-.037.1 7.389 7.389 0 00-.442 1.216 8.192 8.192 0 00-.313 1.695 6.272 6.272 0 00-.04.875c.011.477.049.884.049.884a7.909 7.909 0 00.295 1.492 9.092 9.092 0 006.944 6.576l1.971-5.268v-18.6zm-3.951 22.664a10.6 10.6 0 001.151.617 9.214 9.214 0 01-1.151-.617z"
                data-name="Path 9965"
                transform="translate(-840.494 -463.657)"
              ></path>
            </g>
            <g
              data-name="Group 31561"
              data-type="innerShadowGroup"
              transform="translate(11.592 22.664)"
            >
              <path
                fill="#fff"
                d="M847.39 488.884a9.214 9.214 0 01-1.151-.617 10.61 10.61 0 001.151.617z"
                data-name="Path 9966"
                transform="translate(-846.239 -488.267)"
              ></path>
              <g filter="url(#Path_9966)" transform="translate(-11.59 -22.66)">
                <path
                  fill="#fff"
                  d="M847.39 488.884a9.214 9.214 0 01-1.151-.617 10.61 10.61 0 001.151.617z"
                  data-name="Path 9966"
                  transform="translate(-834.65 -465.6)"
                ></path>
              </g>
            </g>
            <g data-name="Group 31563" transform="translate(5.24)">
              <path
                fill="#fff"
                d="M841.97 470.619l1.98.617a8.39 8.39 0 00-1.234 1.188l-3.177-1.142.783-.939c1.142-1.345 3.14-4.522 2.1-5.719l.884-.967c1.759 2.008-.166 5.305-1.336 6.962z"
                data-name="Path 9968"
                transform="translate(-839.539 -463.657)"
              ></path>
            </g>
            <g data-name="Group 31564" transform="translate(4.798 17.295)">
              <path
                fill="#fff"
                d="M840.146 493.387l-.8-1c.847-.691.589-5.222-.157-9.025l-.129-.654 1.667-.267a9.266 9.266 0 00.424 1.234l-.571.092c1.362 7.502.026 9.261-.434 9.62z"
                data-name="Path 9969"
                transform="translate(-839.059 -482.437)"
              ></path>
            </g>
            <g data-name="Group 31565" transform="translate(0 10.25)">
              <path
                fill="#fff"
                d="M840.765 475.671a7.387 7.387 0 00-.442 1.216l-1.1-.424c-2.56 7.174-4.6 8.031-5.185 8.123l-.193-1.271c1.087-.175 3.122-4.227 4.384-7.9l.212-.626z"
                data-name="Path 9970"
                transform="translate(-833.849 -474.787)"
              ></path>
            </g>
            <g data-name="Group 31567" transform="translate(22.597)">
              <g data-name="Group 31566">
                <path
                  fill="#fff"
                  d="M863.189 471.273l-3.942 1.363a8.215 8.215 0 00-.967-.995 1.207 1.207 0 00-.092-.138l2.56-.884c-1.179-1.658-3.094-4.955-1.335-6.962l.893.958c-1.041 1.2.967 4.374 2.1 5.719z"
                  data-name="Path 9971"
                  transform="translate(-858.189 -463.657)"
                ></path>
              </g>
            </g>
            <g
              data-name="Group 31568"
              data-type="innerShadowGroup"
              transform="translate(11.592 22.664)"
            >
              <path
                fill="#fff"
                d="M847.39 488.884a9.214 9.214 0 01-1.151-.617 10.61 10.61 0 001.151.617z"
                data-name="Path 9972"
                transform="translate(-846.239 -488.267)"
              ></path>
              <g filter="url(#Path_9972)" transform="translate(-11.59 -22.66)">
                <path
                  fill="#fff"
                  d="M847.39 488.884a9.214 9.214 0 01-1.151-.617 10.61 10.61 0 001.151.617z"
                  data-name="Path 9972"
                  transform="translate(-834.65 -465.6)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BugXLogoIcon;
