
interface ContainerProps {
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const Display: React.FC = () => {
  return (
    <div id="transmission" className='py-24 bg-white'>
      <Container>      
        <div className="space-y-12 justify-between md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center mx-auto">   
          <div className="md:7/12 lg:w-2/5">
            <h2 className="text-1xl font-bold uppercase tracking-widest text-black">
            DISPLAY
            </h2>
            <h3 className="text-6xl font-bold text-black uppercase">
            IT TURNS HEADS, BUT THERE’S MORE.
            </h3>
            <div className='w-16 bg-limegreen h-1 my-7 rounded'></div>
            <p className="my-4 text-black">
            Engage the throttle and feel the winds drift over you, while emotions from onlookers rise as you speed past them. And at nights, the built in display just dazzles your night drives with its coloured, water resistant screen, in addition to its letting you keep track of what’s left of the your battery.
            </p>
          </div>      
          <div className="md:5/12 lg:w-2/5">
            <img
              src="./turns_heads.png"
              alt="image"
              loading="lazy"
              className="w-full"
            />
          </div>                     
        </div>
      </Container>
    </div>
  );
};

export default Display;