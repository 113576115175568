import React from 'react';

interface CTAButtonProps {
	className?: string;
	style?: React.CSSProperties;
   title?: string | React.ReactNode;
  }

const CTAButton: React.FC<CTAButtonProps> = ({ className, title, style }) => {
   return (
      <a
         href="#"
         className={`border-white border-2 p-2 relative flex w-full items-center justify-center px-6 before:absolute before:inset-0 text-white font-semibold hover:bg-white hover:text-darkgrey hover:transition hover:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max ${className}`}
		 style={style}
      >
         {title}
      </a>
   );
}

export default CTAButton;
