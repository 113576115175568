import React from 'react';
import { CopyrightIcon, FacebookIcon, InstagramIcon, MailIcon, MapPinIcon, PhoneCallIcon, XIcon } from 'lucide-react';
import BugXLogoIconAlt from '../icons/BugXLogoIconAlt';

const links = [
  { key: 'link-1', href: '#', name: 'Pre-Order' },
  { key: 'link-2', href: '#', name: 'Charging Stations' },
  { key: 'link-3', href: '#', name: 'Support' },
  { key: 'link-4', href: '#', name: 'Mobile App' },
  { key: 'link-5', href: '#', name: 'Partner with Us' },
  { key: 'link-6', href: '#', name: 'About Us' },
];

const moreLinks = [
  { key: 'more-1', href: '#', name: 'Electrify your Bike' },
  { key: 'more-2', href: '#', name: 'Product Manuals' },
  { key: 'more-3', href: '#', name: 'Our partners' },
];

const Footer: React.FC = () => {
  return (
    <footer className="pt-24 pb-8 bg-white">
      <div className="max-w-7xl mx-auto px-2 sm:px-3 lg:px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <div className="space-y-4">
            <a href="#home" aria-label="logo" className="inline-block">
              <BugXLogoIconAlt className="text-black" />
            </a>
            <p className="text-sm">
              Bug-X creates innovative solutions tailored for students, workers and professionals. Our e-bikes integrate high-performance motors, extended-range batteries and satellite trackers. We are also ensuring seamless mobility for riders through our solar-powered battery charging and swap network.
            </p>
            <div className="flex gap-4">
              <FacebookIcon className="w-5 h-5" />
              <XIcon className="w-5 h-5" />
              <InstagramIcon className="w-5 h-5" />
            </div>
          </div>

          <div className="space-y-4 mt-12">
            <div className="flex items-start gap-2">
              <MailIcon className="w-5 h-5 mt-1 flex-shrink-0" />
              <span>info@bugxlabs.com</span>
            </div>
            <div className="flex items-start gap-2">
              <MapPinIcon className="w-5 h-5 mt-1 flex-shrink-0" />
              <span>#7 Street CD, Federal Lowcost Housing, Phase 1, Beside Season 7 Restaurant, Gwagwalada, 902101, FCT, Nigeria.</span>
            </div>
            <div className="flex items-start gap-2">
              <PhoneCallIcon className="w-5 h-5 mt-1 flex-shrink-0" />
              <span>+234 (0) 703 720 1484, +234 (0) 706 305 8322</span>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="font-bold">Quick links</h3>
            <div className="w-12 bg-gray-500 h-1 rounded"></div>
            <ul className="space-y-2">
              {links.map(link => (
                <li key={link.key}><a href={link.href}>{link.name}</a></li>
              ))}
            </ul>
          </div>

          <div className='mt-12'>
            <ul className="space-y-2">
              {moreLinks.map(link => (
                <li key={link.key}><a href={link.href}>{link.name}</a></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
	  <div className='h-[0.1rem] w-full bg-gray-400 my-8' />
	  <div className='flex justify-between max-w-7xl mx-auto px-2 sm:px-3 lg:px-4'>
		<span className='flex items-center gap-1'><CopyrightIcon className="w-4 h-4" /> <b>Bug X Labs</b> 2024. All rights reserved</span>
		<ul className='flex gap-4'>
			<li><a href='#'>Privacy Policy</a></li>
			<li><a href='#'>Terms of Reference</a></li>
		</ul>
	  </div>
    </footer>
  );
};

export default Footer;