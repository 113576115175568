import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import BugXLogoIcon from "../../icons/BugXLogoIcon";
import { ArrowDown, ChevronDown, CirclePlay, Search } from "lucide-react";
import Navigation from "../../components/Navigation";
import Hero from "../../components/Hero";
import WhatsBugX from "../../components/WhatsBugX";
import HomeSectionsContainer from "../../components/HomeSectionsContainer";
import ElectrifyOldBike from "../../components/ElectrifyOldBike";
import TalkToUs from "../../components/TalkToUs";
import ComingSoon from "../../components/ComingSoon";
import Testimonial from "../../components/Testimonial";
import DriveIn from "../../components/DriveIn";
import Footer from "../../components/Footer";

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="relative bg-white w-full text-left text-sm text-darkslategray-200 font-montserrat">

      {/* Navigation */}
      {/* <nav className="fixed top-0 left-0 w-full h-20 bg-darkgrey text-white flex items-center justify-between px-14">
        <BugXLogoIcon />
        <div className="flex items-center gap-8">
          <div className="flex items-center">
            <b className="text-limegreen active active:underline">E-Bikes</b>
            <ChevronDown className="text-limegreen font-thin" />
          </div>
          <button onClick={onInUtOdioClick1} className="hover:text-limegreen">Charging</button>
          <button onClick={onGroupContainerClick} className="hover:text-limegreen">Support</button>
          <button className="hover:text-limegreen">About</button>
          <button><Search size={16} /></button>
        </div>
        <button onClick={onInUtOdioClick} className="bg-limegreen text-darkgrey px-4 py-2 rounded-xl font-bold">
          Pre-order
        </button>
      </nav> */}

      <Navigation />
      <Hero />
      <WhatsBugX />
      <HomeSectionsContainer />
      <ElectrifyOldBike />
      <TalkToUs />
      <ComingSoon />
      <Testimonial />
      <DriveIn />
      <Footer />
    </div>
  );
};

export default Home;