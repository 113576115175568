import { ArrowRight, CirclePlay } from 'lucide-react';
import React from 'react';
import CTAButton from './CTAButton';
import CTAButtonAlt from './CTAButtonAlt';

interface ContainerProps {
   children: React.ReactNode;
   className?: string;
   style?: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = ({ children, className, style }) => (
   <div className={`max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 ${className}`} style={style}>
      {children}
   </div>
);

const features = [
   {
      key: 'transmission',
      name: 'Pedal Assisted',
      area: 'Transmission',
      icon: (
         <img
            src="./pedal_assisted.png"
            className="h-fit w-fit"
            alt="Pedal assisted feature"
         />
      ),
   },
   {
      key: 'operability',
      name: 'Easy',
      area: 'Operability',
      icon: (
         <img
            src="./pedal_assisted.png"
            className="h-fit w-fit"
            alt="Pedal assisted feature"
         />
      ),
   },
   {
      key: 'fast',
      name: 'Fast',
      area: 'Charging',
      icon: (
         <img
            src="./pedal_assisted.png"
            className="h-fit w-fit"
            alt="Pedal assisted feature"
         />
      ),
   },
   {
      key: 'charge',
      name: '20 - 70 km',
      area: 'Per Charge',
      icon: (
         <img
            src="./pedal_assisted.png"
            className="h-fit w-fit"
            alt="Pedal assisted feature"
         />
      ),
   },
   ,
   {
      key: 'tracking',
      name: '48hr Extended',
      area: 'GPS Tracking',
      icon: (
         <img
            src="./pedal_assisted.png"
            className="h-fit w-fit"
            alt="Pedal assisted feature"
         />
      ),
   },
];

const DriveIn: React.FC = () => {
   return (
      <div
         className="relative flex flex-col bg-cover bg-center"
         style={{
            backgroundImage: "url('./drive_in.png')",
            backgroundRepeat: 'no-repeat',
			backgroundPosition: 'top',		
         }}
         id="home"
      >
         <div className="relative inset-0 bg-black bg-opacity-10"></div>
         <Container>
            <div className="relative flex flex-col items-end py-24 lg:w-1/2">
               <div className="flex flex-col flex-wrap">
                  <h1 className="text-white font-bold text-2xl md:text-3xl xl:text-4xl">
                     DRIVE IN TO OUR SUPPORT CENTRE, ANYTIME.
                  </h1>
                  <CTAButtonAlt
                     className="mt-8 text-limegreen border-limegreen"
                     title={
                        <div className="flex gap-2">
                           FIND SUPPORT CENTRE{' '}
                           <ArrowRight
                              className="hover:translate-x-2 duration-500"
                              fontSize={8}
                           />
                        </div>
                     }
                  />
               </div>
            </div>
         </Container>
         <div className="bg-limegreen h-1 my-8" />
		<Container>
		<div className="space-y-12 justify-between md:flex md:gap-6 md:space-y-0 lg:gap-12 mx-auto py-8">
			{features.map((item) => (
				<div className='flex'>
					<div className="flex flex-col items-center lg:w-9/12">
						{item?.icon}
						<div className="text-white mt-1 font-bold">{item?.name}</div>
						<div className="text-white">{item?.area}</div>
					</div>
				</div>
			))}
			</div>
		</Container>
      </div>
   );
};

export default DriveIn;
