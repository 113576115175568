
interface ContainerProps {
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const Lights: React.FC = () => {
  return (
    <div id="transmission" className='py-24 bg-white'>
      <Container>      
        <div className="space-y-12 justify-between md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center mx-auto">
          <div className="md:5/12 lg:w-2/5">
            <img
              src="./shine_on.png"
              alt="image"
              loading="lazy"
              className="w-full"
            />
          </div>           
          <div className="md:7/12 lg:w-2/5">
            <h2 className="text-1xl font-bold uppercase tracking-widest text-black">
              LIGHTS
            </h2>
            <h3 className="text-6xl font-bold text-black uppercase">
              SHINE ON, EVEN IN THE DARK.
            </h3>
            <div className='w-16 bg-limegreen h-1 my-7 rounded'></div>
            <p className="my-4 text-black">
              Your e-bike incorporates a modern lighting array for optimal visibility and safety. With adjustable LED headlamps, your light beams at exactly where you want it, ensuring clear visibility in any environment. The energy-efficient design integrates seamlessly with the e-bike’s power system, providing reliable illumination without compromising range.
            </p>
          </div>         
        </div>
      </Container>
    </div>
  );
};

export default Lights;