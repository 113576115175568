
interface ContainerProps {
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const Transmission: React.FC = () => {
  return (
    <div id="transmission" className='py-24 bg-white'>
      <Container>      
        <div className="space-y-12 justify-between md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center mx-auto">
          <div className="md:5/12 lg:w-2/5">
            <img
              src="./350_watt.png"
              alt="image"
              loading="lazy"
              className="w-full"
            />
          </div>           
          <div className="md:7/12 lg:w-2/5">
            <h2 className="text-1xl font-bold uppercase tracking-widest text-black">
              Transmission
            </h2>
            <h3 className="text-6xl font-bold text-black uppercase">
              350 WATTS OF RAW POWER.
            </h3>
            <div className='w-16 bg-limegreen h-1 my-7 rounded'></div>
            <p className="my-4 text-black">
            Bug-X e-bikes are powered by a state-of-the-art electric drive system. Take the 350W Pro or the 250W Base edition, the DC motors deliver robust performance, effortlessly tackling inclines up to 20%. The seamless transmission provides variable levels of intelligent pedal assist, dynamically adapting to your riding style and terrain for optimal efficiency and range.
            </p>
          </div>         
        </div>
      </Container>
    </div>
  );
};

export default Transmission;