
interface ContainerProps {
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ children }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
);

const Range: React.FC = () => {
  return (
    <div id="transmission" className='py-24 bg-white'>
      <Container>      
        <div className="space-y-12 justify-between md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center mx-auto">
          <div className="md:5/12 lg:w-2/5">
            <img
              src="./endless_journeys.png"
              alt="image"
              loading="lazy"
              className="w-full"
            />
          </div>           
          <div className="md:7/12 lg:w-2/5">
            <h2 className="text-1xl font-bold uppercase tracking-widest text-black">
            RANGE
            </h2>
            <h3 className="text-6xl font-bold text-black uppercase">
              ENDLESS JOURNEYS WITH EVERY CHARGE
            </h3>
            <div className='w-16 bg-limegreen h-1 my-7 rounded'></div>
            <p className="my-4 text-black">
            Experience between 20 - 70 kilometres on a single charge with Bug-X e-bikes. But why stop there? Our solar-powered battery swap network transforms your commute into an endless journey. Go as far as your ambitions take you, without the constraints of traditional range limitations.
            </p>
          </div>         
        </div>
      </Container>
    </div>
  );
};

export default Range;